@use "@ey-xd/motif-core/src/styles/themes/design-patterns/4.0/mixins" as mixin;
@use "../styles/easings" as easings;


.motif-modal-root {
  opacity: 0;
}

.eoy-app {
  visibility: hidden;
  opacity: 0;
}


.eoy-app-loaded {
  .eoy-app {
    visibility: visible;
    opacity: 1;
    transition: opacity .5s easings.$ease-out-quint;
  }

  .motif-modal-root {
    visibility: visible;
    opacity: 1;
    transition: opacity .5s easings.$ease-out-quint;

    .eoy-modal {
      &.motif-modal {
        animation: 
          slide-in .75s easings.$ease-out-quint, 
          fade-in .5s easings.$ease-out-quint;
      }
    }
  }
}



.eoy-progress-loader{
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eoy-header {
  &.motif-header {
    .motif-header-row {
      .motif-header-company-container {
        padding: 0 12px;

        .motif-header-logo {
          height: auto;
          bottom: 0;
        }

        .motif-header-app-name {
          margin-bottom: 0;
          line-height: 34px;
        }
      }
    }
  }
}

.eoy-content-container {
  &.motif-container {
    padding: 16px 20px 60px;
    min-height: calc(100vh - 515px);

    @include mixin.mq(medium) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @include mixin.mq(large) {
      padding: 16px 16px 80px;
      max-width: 1312px;
    }
  }
}

.eoy-pagination-container {
  display: flex;
  padding: 48px 0 0;
  justify-content: center;
  height: 32px;
  opacity: 0;
  animation: fade-pagination-in .5s easings.$ease-out-quint .25s forwards;
}

@keyframes fade-pagination-in {
  from {opacity: 0; transform: translateY(35px);}
  to {opacity: 1; transform: translateY(0);}
}

.eoy-no-results-container {
  display: block;
  margin: 16px 0 0 12px;
 
  h2 {
    font-size: 24px;
    margin-bottom: 8px;
  }

  p {
    font-size: 16px;
  }
}

// Hero Title
.eoy-title {
  color: var(--hero-text-color);
  font-weight: var(--weight-regular);
  font-size: var(--h3--font-size);
  margin: 0;
  margin-bottom: 12px;

  @include mixin.mq(medium) {
    font-weight: var(--weight-light);
    font-size: 3rem;
    margin-top: 8px;
    margin-bottom: 56px;
  }
}

// Search Input Component
.eoy-search-input {
  position: relative;
  max-width: 50%;

  &.motif-search-input-wrapper {
    .motif-input {
      color: var(--form-element-color);
      padding-left: 0;
      padding-right: 0;

      &:hover {
        background-color: transparent;
      }
    }

    .motif-icon-button {
      color: var(--form-element-color);
      right: 0;
  
      &:hover {
        background-color: transparent;
        color: var(--form-element-hover-color);
      }

      &:focus {
        background-color: var(--share-btn-hover-color);
      }
    }
  }

  .motif-typeahead-screen-reader {
    display: none;
  }
}

.eoy-pagination-wrapper {
  display: flex;
  max-width: 66%;
}

.eoy-option-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

.eoy-left-nav-wrapper,
.eoy-right-nav-wrapper {
    display: flex;
  }

  .eoy-pagination-button {
    width: 3rem;
    height: 4rem;
  }

  .eoy-sort-container {
    margin-top: 16px;
    margin-bottom: 0;
  }
  
  @include mixin.mq(medium) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    .eoy-select-container {
      width: 250px;
    }
  }
}

// Tab Nav Component
.eoy-tab-nav {
  position: relative;
  overflow-x: scroll;
  
  &.motif-tab-navigation-horizontal {
    border: none;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
  }

  &.motif-tab-navigation-horizontal::-webkit-scrollbar {
    display: none;
  }

  &.motif-tab-navigation {
    .motif-tab-button {
      @extend .motif-main-navigation !optional;

      border-bottom-width: 3px;

      &:focus {
        outline-color: var(--tab-accent-color);
      }

      &:hover:not([disabled]) {
        text-shadow: none;
        box-shadow: 0 1px 0 0 var(--tab-accent-color);
        border-bottom-color: var(--tab-accent-color);
        color: var(--tab-accent-color);
      }

      &.motif-active {
        @extend .motif-main-navigation-active !optional;
      }
    }
  }
}

// Tablet & Mobile Filter
.eoy-subnav-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 16px;

  @include mixin.mq(medium) {
    margin-top: 20px;
  }

  .eoy-dropdown-menu {
    .eoy-dropdown-trigger {
      color: var(--dropdown-content-color); 

      &:hover {
        color: var(--dropdown-content-hover-color);
      }

      .motif-icon {
        display: flex;
        align-items: center;
        color: inherit;
      }
    } 

    @include mixin.mq(large) {
      display: none;
    }
  }
}

.eoy-dropdown-menu button.motif-icon-button  {
  color: var(--dropdown-content-color);
  display: inline-flex;
  align-items: center;

  svg {
    color: var(--dropdown-content-color);
  }

  @include mixin.mq(large) {
    display: none;
  }
}

.eoy-dropdown-reset-wrapper {
  border-bottom: 1px solid var(--dropdown-border-color);
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;

  .motif-text-button:not([disabled]) {
    color: var(--dropdown-content-color);
  }

  .motif-icon-button.eoy-close-filter-button {
    margin-right: 24px;

    &:focus {
      background-color: var(--share-btn-hover-color);
    }

    .motif-icon svg  {
      color: var(--dropdown-content-color);
    }
  }

  .eoy-dropdown-focus-wrapper {
    display: inline-flex;
  }

  .eoy-reset-button.motif-text-button {
    padding-left: 12px;
    margin-bottom: 0;
    font-weight: var(--weight-bold);
    

    &:hover {
      margin-bottom: 0;
      border-bottom: none;
    }
  }
  
  @include mixin.mq(medium) {
    padding: 0;
  }
  
}

.motif-dropdown-menu-portal.eoy-dropdown-menu {
  color: var(--dropdown-content-color);
  background-color: var(--hero-bg-color);
  height: 100vh;
  width: 100vw;
  line-height: 22px;
  overflow-y: scroll;
  scroll-padding-right: 0;

  .eoy-dropdown-sm-filter {
    padding-left: 20px;
  }

  .eoy-filter-sm-options {
    height: calc(100vh - 172px);
    overflow-y: scroll;

    @include mixin.mq(medium) {
      min-height: auto;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .motif-dropdown-arrow {
    display: none;
  }

  .motif-form-field {
    padding: 16px;
    margin-bottom: 0;
  }

  .eoy-apply-sm-filter {
    border-top: 1px solid var(--dropdown-border-color);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    bottom: 0;
    position: sticky;
    height: 122px;
    background-color: var(--btn-primary-alt-bg-color);
    padding: 16px 20px 60px;
    align-items: flex-end;

    .motif-button-primary-alt {
      border: 1px solid var(--dropdown-content-color);
      width: 100%;
      height: 44px;
    }
  }

  @include mixin.mq(medium) {
    height: auto;
    width: auto;
    margin-top: 8px;
    overflow-y: visible;
    padding-bottom: 10px;

    .eoy-dropdown-sm-filter,
    .eoy-close-filter-button,
    .eoy-apply-sm-filter {
      display: none;
    }

    .eoy-filter-sm-options {
      height: auto;
      overflow-y: visible;
    }
  
    .motif-form-field {
      padding: 12px 32px 12px 12px;
    }
  
  }

  @include mixin.mq(large) {
    display: none;
  }
}

// Added to preserve existing Motif styles
@media (max-width: 767px) {
  .motif-dropdown-menu-portal.eoy-dropdown-menu {
    transform: none !important;
    position: fixed !important;
  }

  .eoy-pagination-wrapper {
    max-width: none;
  }
}


// Desktop Filter Layout
.eoy-filter-hide {
  display: none;
  margin-top: 16px;

  @include mixin.mq(large) {
    display: block;

    .eoy-filter-wrapper {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
      
      .motif-tree > .eoy-tree-node {
        margin-bottom: 14px;
      }
    }

    .eoy-filter-title {
      font-weight: var(--weight-light);
    }

    .eoy-reset-button {
      &.motif-text-button {
        font-size: var(--font-size-base);
        margin-bottom: 16px;
        height: 24px;
    
        &:hover {
          margin-bottom: 16px;
        }
      }
    }
  }
}

// 404 Page
.eoy-404-wrapper {
  min-height: calc(100vh - 140px);
  max-width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  

  .eoy-404-header {
    @extend .eoy-title;

    color: var(--body--color);

    @include mixin.mq(medium) {
      margin-bottom: 32px;
    }
  }

  p {
    margin-bottom: 24px;
  }

  .eoy-404-link {
    margin: 16px auto 0;
    display: block;
    text-decoration: none;
    max-width: 200px;

    .eoy-404-button {
      min-width: 200px;
    }

    @include mixin.mq(medium) {
      margin: 16px 8px 8px;
      display: inline-block;
    }
  }
}

.eoy-select-all-button {
  background-color: transparent;
  color: var(--body--color);
  border-color: transparent;
  min-width: 10%;
  font-weight: var(--weight-light);
  border-bottom-width: 3px;
  border-radius: 0;

  &:focus {
    outline-color: var(--tab-accent-color);
  }

  &:hover {
    text-shadow: none;
    box-shadow: 0 1px 0 0 var(--tab-accent-color);
    border-bottom-color: var(--tab-accent-color);
    color: var(--tab-accent-color);
    background-color: var(--all-button-hover-bg);
  
  }
}

.eoy-all-button-active {
  border-bottom-color: var(--all-button-border-bottom);
}

@media (max-width: 767px) {
  .eoy-search-input {
    max-width: 100%;
  }
}

